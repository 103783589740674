<template>
  <fragment>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex flex-wrap align-items-center">
                <div
                  class="
                    profile-img
                    position-relative
                    me-3
                    mb-3 mb-lg-0
                    profile-logo profile-logo1
                  "
                >
                  <img
                    :src="require(`@assets/images/avatars/${genderAvatar}`)"
                    :alt="user.username"
                    class="img-fluid rounded-pill avatar-100 bg-white"
                  />
                </div>
                <div class="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                  <h4 class="me-2 h4">{{ nameComplete }}</h4>
                  <span> - {{ roleName }}</span>
                </div>
              </div>
	      <span class="d-flex mb-0 text-center align-items-center">
		<b class="mr-2">{{ updateText }}</b>
	      </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <ul id="top-tab-list" class="p-0 row list-inline">
          <li class="col-lg-3 col-md-6 text-start mb-2">
            <a href="javascript:void(0)" class="bg-custom4" ref="personal">
              <div class="iq-icon me-3 bg-custom">
                <i class="fas fa-address-card" aria-hidden="true" />
              </div>
              <span>Datos Personales</span>
            </a>
          </li>
          <li class="col-lg-3 col-md-6 text-start mb-2">
            <a href="javascript:void(0)" class="bg-custom4" ref="location">
              <div class="iq-icon me-3 bg-custom">
                <i class="fas fa-map-marker-alt" aria-hidden="true" />
              </div>
              <span>Ubicación</span>
            </a>
          </li>
          <li class="col-lg-3 col-md-6 text-start mb-2">
            <a href="javascript:void(0)" class="bg-custom4" ref="working">
              <div class="iq-icon me-3 bg-custom">
                <i class="fas fa-briefcase" aria-hidden="true" />
              </div>
              <span>Datos Laborales</span>
            </a>
          </li>
          <li class="col-lg-3 col-md-6 text-start mb-2">
            <a href="javascript:void(0)" class="bg-custom4" ref="contact">
              <div class="iq-icon me-3 bg-custom">
                <i class="fas fa-address-book" aria-hidden="true" />
              </div>
              <span>Datos Contacto</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-12">
        <div class="profile-content tab-content">
          <div ref="personal_content" class="tab-pane fade">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h4 class="card-title">Datos Personales</h4>
                </div>
              </div>
              <div class="card-body">
                <div class="new-user-info" v-if="Object.keys(user).length">
                  <form-personal :submit="updateForm" :data="user" />
                </div>
              </div>
            </div>
          </div>
          <div ref="location_content" class="tab-pane fade">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h4 class="card-title">Ubicacion</h4>
                </div>
              </div>
              <div class="card-body">
                <div class="new-user-info" v-if="Object.keys(user).length">
                  <form-ubication :submit="updateForm" :data="user" />
                </div>
              </div>
            </div>
          </div>
          <div ref="working_content" class="tab-pane fade">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h4 class="card-title">Datos Laborales</h4>
                </div>
              </div>
              <div class="card-body">
                <div class="new-user-info" v-if="Object.keys(user).length">
                  <form-working :submit="updateForm" :data="user" />
                </div>
              </div>
            </div>
          </div>
          <div ref="contact_content" class="tab-pane fade">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h4 class="card-title">Datos Contacto</h4>
                </div>
              </div>
              <div class="card-body">
                <div class="new-user-info" v-if="Object.keys(user).length">
                  <form-contact :submit="updateForm" :data="user" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coupon :id="modalID" :close="closeCouponModal" v-if="modalCoupon" />
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormPersonal from '@components/user/profile/FormPersonal.vue'
import FormUbication from '@components/user/profile/FormUbication.vue'
import FormWorking from '@components/user/profile/FormWorking.vue'
import FormContact from '@components/user/profile/FormContact.vue'
import Coupon from '@components/user/profile/modal/Coupon.vue'
import { errorRequest } from '@helpers'
import { Modal } from 'bootstrap'
import moment from 'moment'

export default {
  name: 'MyProfile',
  components: {
    FormPersonal,
    FormUbication,
    FormWorking,
    FormContact,
    Coupon,
  },
  data: function () {
    return {
      modalID: 'coupon',
      modalCoupon: false,
      step: 0,
      listWizard: ['personal', 'location', 'working', 'contact'],
    }
  },
  watch: {
    step: function (newVal, oldVal) {
      let steps = this.listWizard
      this.$refs[steps[oldVal]].classList.remove('bg-custom2')
      this.$refs[steps[oldVal]].classList.add('bg-custom4')
      this.$refs[`${steps[oldVal]}_content`].classList.remove('active', 'show')
      this.stepWizardStyle()
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/userData',
    }),
    nameComplete() {
      if (this.user) {
        let first_name = this.user.first_name
        let first_last_name = this.user.first_last_name
        return `${first_name} ${first_last_name}`
      }
      return '-'
    },
    roleName() {
      if (this.user) {
        let role = this.user?.role
        return role?.name
      }
      return '-'
    },
    genderAvatar() {
      if (this.user) {
        let gender = this.user.gender
        return parseInt(gender) === 1 ? 'man.png' : 'woman.png'
      }
      return '01.png'
    },
    updateText() {
      if (this.user) {
        let updated = this.user?.user_updated
        if (updated) {
          let updated_at = this.user.updated_at
          let time = moment(updated_at).format('HH:mm a')
          let date = moment(updated_at).format('DD/MM/YYYY')
          return `
            Tu perfil se actualizo: ${date} a las ${time} 😃
          `
        }
      }

      return 'Aún no has actualizado tu perfil 😢'
    },
  },
  mounted() {
    this.stepWizardStyle()
  },
  methods: {
    ...mapActions({
      updateAction: 'user/update',
    }),
    async updateForm(user, user_updated) {
      if (!this.user.user_updated) {
        if (user_updated) user.user_updated = 1
        await this.updateAction(user).then(
          async (response) => {
            this.loading = false
            return await this.$parent.getUserData()
          },
          (error) => {
            this.loading = false
            return errorRequest(error, this.$swal)
          }
        )
      }
    },
    stepWizardStyle() {
      let steps = this.listWizard
      for (let i = 0; i < this.step + 1; i++) {
        this.$refs[steps[i]].classList.remove('bg-custom4')
        this.$refs[steps[i]].classList.add('bg-custom2')
      }
      this.$refs[`${steps[this.step]}_content`].classList.add('active', 'show')
    },
    nextStep() {
      let countListWizard = this.listWizard.length
      if (this.step < countListWizard - 1) this.step++
    },
    previousStep() {
      this.step--
    },
    openCouponModal() {
      this.modalCoupon = true
      this.$nextTick(() => {
        let couponModal = new Modal(document.getElementById(this.modalID), {})
        return couponModal.show()
      })
    },
    closeCouponModal() {
      setTimeout(() => (this.modalCoupon = false), 100)
    },
  },
}
</script>

<style scoped>
.show-wizard {
  opacity: 1;
  position: relative;
  display: block;
}

.hidden-wizard {
  opacity: 0;
  position: relative;
  display: none;
}
</style>
