<template>
  <fragment>
    <form class="row g-3" @submit.prevent="updateForm">
      <div class="col-md-6">
        <label class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          :class="{ 'is-invalid': $v.user.email.$error }"
          placeholder="Email"
          v-model.trim="$v.user.email.$model"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label">Teléfono</label>
        <input
          type="number"
          class="form-control"
          :class="{ 'is-invalid': $v.user.telephone.$error }"
          placeholder="Teléfono"
          v-model.trim="$v.user.telephone.$model"
        />
      </div>
      <div class="col-md-12">
        <label class="form-label">Observaciones</label>
        <textarea
          class="form-control"
          :class="{ 'is-invalid': $v.user.observations.$error }"
          placeholder="Observaciones"
          v-model.trim="$v.user.observations.$model"
        />
      </div>

      <div class="mt-4 modal-footer border-top border-5 border-light">
        <div v-show="!loading">
          <button
            type="submit"
            class="btn btn-success float-end"
            v-if="saveButton"
          >
            <span class="fas fa-save" aria-hidden="true"></span> Guardar Cambios
          </button>
          <button
            type="button"
            class="btn btn-dark float-end me-1"
            @click="previousStep"
          >
            <span class="fas fa-arrow-left" aria-hidden="true"></span> Regresar
          </button>
        </div>
        <div v-show="loading">
          <button class="btn btn-success disabled">
            <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
            Cargando
          </button>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { successNotify } from '@helpers'

export default {
  name: 'FormContact',
  props: {
    submit: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      saveButton: true,
      user: {},
    }
  },
  validations() {
    let validate = {
      email: { required },
      telephone: { required },
      observations: {},
    }
    return {
      user: validate,
    }
  },
  mounted() {
    this.user = { ...this.data }
    if (this.user.user_updated) this.saveButton = false
  },
  methods: {
    async updateForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.submit(
          {
            id: this.user.id,
            email: this.user.email,
            telephone: this.user.telephone,
            observations: this.user.observations,
          },
          true
        )
        successNotify(
          {
            message: 'Actualizado con exito',
          },
          this.$swal
        )
        this.saveButton = false
      }
      this.loading = false
    },
    previousStep() {
      this.$parent.previousStep()
    },
  },
}
</script>
