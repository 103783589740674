<template>
  <fragment>
    <form class="row g-3" @submit.prevent="updateForm">
      <div class="col-md-6">
        <label class="form-label">Residencia</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.residence_id.$error }"
          v-model="$v.user.residence_id.$model"
          :options="countries.map((type) => type.id)"
          :custom-label="countriesSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-6">
        <label class="form-label">Tipo Vivienda</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.housing_type_id.$error }"
          v-model="$v.user.housing_type_id.$model"
          :options="housing_types.map((type) => type.id)"
          :custom-label="housingTypesSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-6">
        <label class="form-label">Dirección</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.address.$error }"
          placeholder="Dirección"
          v-model.trim="$v.user.address.$model"
        />
      </div>
      <div class="col-md-6">
        <label class="form-label">Lugar Nacimiento</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.born_place.$error }"
          placeholder="Lugar Nacimiento"
          v-model.trim="$v.user.born_place.$model"
        />
      </div>

      <div class="mt-4 modal-footer border-top border-5 border-light">
        <div v-show="!loading">
          <button type="submit" class="btn btn-custom2 float-end">
            Siguiente
            <span class="fas fa-arrow-right" aria-hidden="true"></span>
          </button>
          <button
            type="button"
            class="btn btn-dark float-end me-1"
            @click="previousStep"
          >
            <span class="fas fa-arrow-left" aria-hidden="true"></span> Regresar
          </button>
        </div>
        <div v-show="loading">
          <button class="btn btn-custom2 disabled">
            <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
            Cargando
          </button>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormUbication',
  props: {
    submit: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.helper.countryData,
      housing_types: (state) => state.helper.housingTypeData,
    }),
  },
  validations() {
    let validate = {
      residence_id: { required },
      housing_type_id: { required },
      address: { required },
      born_place: { required },
    }
    return {
      user: validate,
    }
  },
  mounted() {
    this.user = { ...this.data }
  },
  methods: {
    async updateForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.submit(
          {
            id: this.user.id,
            residence_id: this.user.residence_id,
            housing_type_id: this.user.housing_type_id,
            address: this.user.address,
            born_place: this.user.born_place,
          },
          false
        )
        this.$parent.nextStep()
      }
      this.loading = false
    },
    previousStep() {
      this.$parent.previousStep()
    },
    housingTypesSelect(opt) {
      if (this.housing_types.length) {
        return this.housing_types.find((x) => x.id === opt).name
      }
      return null
    },
    countriesSelect(opt) {
      if (this.countries.length) {
        return this.countries.find((x) => x.id === opt).name
      }
      return null
    },
  },
}
</script>
