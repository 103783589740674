<template>
  <modal :id="id" :size="size">
    <template v-slot:header>
      <h2 class="h5 modal-title"
        ><span class="fas fa-donate" aria-hidden="true"></span> Ticket de
        Sorteo</h2
      >
      <a data-bs-dismiss="modal" aria-label="Close" @click="close">
        <span class="fa fa-times" aria-hidden="true"></span>
      </a>
    </template>
    <template v-slot:body>
      <div class="row g-3">
        <div class="col-md-12">
          <div class="container">
            <div class="card">
              <div class="main">
                <div class="co-img">
                  <img src="@assets/images/icon_coopetro.png" alt="" />
                </div>
                <div class="vertical"></div>
                <div class="content">
                  <h2>Gran Sorteo</h2>
                  <h1>Coopetro</h1>
                  <p>Usted ya esta participando</p>
                </div>
              </div>
              <div class="copy-button">
                <input type="text" readonly :value="user.coupon" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 modal-footer border-top border-5 border-light">
          <button
            type="button"
            class="btn btn-dark mr-2"
            data-bs-dismiss="modal"
            @click="close"
            ref="closeModal"
          >
            <span class="fa fa-times" aria-hidden="true"></span> Cerrar
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/app/Modal.vue'
import { mapState } from 'vuex'

export default {
  name: 'Coupon',
  props: ['id', 'close'],
  components: {
    Modal,
  },
  data: function () {
    return {
      size: 'md',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data,
    }),
  },
  methods: {},
}
</script>

<style scoped>
.container {
  height: 30vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card {
  width: 400px;
  height: 180px;
  border-radius: 5px;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 50%);
  background-color: #fff;
  padding: 10px 10px;
  position: relative;
  margin-bottom: 0;
}

.main,
.copy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.co-img img {
  width: 90px;
  height: 90px;
}
.vertical {
  border-left: 5px dotted black;
  height: 100px;
  position: absolute;
  left: 33%;
}

.content h1 {
  font-size: 35px;
  margin-left: -20px;
  color: #565656;
}

.content h1 span {
  font-size: 18px;
}
.content h2 {
  font-size: 18px;
  margin-left: -20px;
  color: #565656;
  text-transform: uppercase;
}

.content p {
  font-size: 16px;
  color: #696969;
  margin-left: -20px;
}

.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

@media (min-width: 400px) and (max-width: 768px) {
  .vertical {
    left: 38% !important;
  }

  .co-img img {
    width: 85px;
    height: 85px;
  }

  .content h2 {
    margin-left: 15px;
  }

  .content h1 {
    margin-left: 12px;
  }

  .content p {
    margin-left: 18px;
  }
}
</style>
