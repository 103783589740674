<template>
  <fragment>
    <form class="row g-3" @submit.prevent="updateForm">
      <div class="col-md-6">
        <label class="form-label">Condición Laboral</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.working_condition_id.$error }"
          v-model="$v.user.working_condition_id.$model"
          :options="working_conditions.map((type) => type.id)"
          :custom-label="workingConditionsSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-6">
        <label class="form-label">Ocupación</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.job_title_id.$error }"
          v-model="$v.user.job_title_id.$model"
          :options="job_titles.map((type) => type.id)"
          :custom-label="jobTitlesSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-6">
        <label class="form-label">Grado Instrucción</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.grade_instruction_id.$error }"
          v-model="$v.user.grade_instruction_id.$model"
          :options="grade_instructions.map((type) => type.id)"
          :custom-label="gradeInstructionsSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-6">
        <label class="form-label">Profesión</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.profession_id.$error }"
          v-model="$v.user.profession_id.$model"
          :options="professions.map((type) => type.id)"
          :custom-label="professionsSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-12">
        <label class="form-label">Actividad Economica</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.economic_activity_id.$error }"
          v-model="$v.user.economic_activity_id.$model"
          :options="economic_activities.map((type) => type.id)"
          :custom-label="economicActivitiesSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-12">
        <label class="form-label">Centro Laboral</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.workplace.$error }"
          placeholder="Centro Laboral"
          v-model.trim="$v.user.workplace.$model"
        />
      </div>

      <div class="mt-4 modal-footer border-top border-5 border-light">
        <div v-show="!loading">
          <button type="submit" class="btn btn-custom2 float-end">
            Siguiente
            <span class="fas fa-arrow-right" aria-hidden="true"></span>
          </button>
          <button
            type="button"
            class="btn btn-dark float-end me-1"
            @click="previousStep"
          >
            <span class="fas fa-arrow-left" aria-hidden="true"></span> Regresar
          </button>
        </div>
        <div v-show="loading">
          <button class="btn btn-custom2 disabled">
            <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
            Cargando
          </button>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormWorking',
  props: {
    submit: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      working_conditions: (state) => state.helper.workingConditionData,
      job_titles: (state) => state.helper.jobTitleData,
      grade_instructions: (state) => state.helper.gradeInstructionData,
      professions: (state) => state.helper.professionData,
      economic_activities: (state) => state.helper.economicActivityData,
    }),
  },
  validations() {
    let validate = {
      working_condition_id: { required },
      job_title_id: { required },
      grade_instruction_id: { required },
      profession_id: { required },
      economic_activity_id: { required },
      workplace: { required },
    }
    return {
      user: validate,
    }
  },
  mounted() {
    this.user = { ...this.data }
  },
  methods: {
    async updateForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.submit(
          {
            id: this.user.id,
            working_condition_id: this.user.working_condition_id,
            job_title_id: this.user.job_title_id,
            grade_instruction_id: this.user.grade_instruction_id,
            profession_id: this.user.profession_id,
            economic_activity_id: this.user.economic_activity_id,
            workplace: this.user.workplace,
          },
          false
        )
        this.$parent.nextStep()
      }
      this.loading = false
    },
    previousStep() {
      this.$parent.previousStep()
    },
    workingConditionsSelect(opt) {
      if (this.working_conditions.length) {
        return this.working_conditions.find((x) => x.id === opt).name
      }
      return null
    },
    jobTitlesSelect(opt) {
      if (this.job_titles.length) {
        return this.job_titles.find((x) => x.id === opt).name
      }
      return null
    },
    gradeInstructionsSelect(opt) {
      if (this.grade_instructions.length) {
        return this.grade_instructions.find((x) => x.id === opt).name
      }
      return null
    },
    professionsSelect(opt) {
      if (this.professions.length) {
        return this.professions.find((x) => x.id === opt).name
      }
      return null
    },
    economicActivitiesSelect(opt) {
      if (this.economic_activities.length) {
        return this.economic_activities.find((x) => x.id === opt).name
      }
      return null
    },
  },
}
</script>
