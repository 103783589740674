<template>
  <fragment>
    <form class="row g-3" @submit.prevent="updateForm">
      <div class="col-md-3">
        <label class="form-label">Apellido Paterno</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.first_last_name.$error }"
          placeholder="Ape. Paterno"
          v-model.trim="$v.user.first_last_name.$model"
        />
      </div>
      <div class="col-md-3">
        <label class="form-label">Apellido Materno</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.second_last_name.$error }"
          placeholder="Ape. Materno"
          v-model.trim="$v.user.second_last_name.$model"
        />
      </div>
      <div class="col-md-3">
        <label class="form-label">Primer Nombre</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.first_name.$error }"
          placeholder="Primer Nombre"
          v-model.trim="$v.user.first_name.$model"
        />
      </div>
      <div class="col-md-3">
        <label class="form-label">Segundo Nombre</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.second_name.$error }"
          placeholder="Segundo Nombre"
          v-model.trim="$v.user.second_name.$model"
        />
      </div>
      <div class="col-md-3">
        <label class="form-label">Tipo Documento</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.type_document_id.$error }"
          v-model="$v.user.type_document_id.$model"
          :options="type_documents.map((type) => type.id)"
          :custom-label="(opt) => type_documents.find((x) => x.id == opt).name"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-3">
        <label class="form-label">Nro Documento</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.user.nro_document.$error }"
          placeholder="Nro Documento"
          v-model.trim="$v.user.nro_document.$model"
        />
      </div>
      <div class="col-md-3">
        <label class="form-label">Género</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.gender.$error }"
          v-model="$v.user.gender.$model"
          :options="genders.map((type) => type.id)"
          :custom-label="(opt) => genders.find((x) => x.id == opt).name"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-3">
        <label class="form-label">Estado Civil</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.marital_status_id.$error }"
          v-model="$v.user.marital_status_id.$model"
          :options="marital_status.map((type) => type.id)"
          :custom-label="maritalStatusSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="col-md-4">
        <label class="form-label">Fecha Nacimiento</label>
        <input
          type="date"
          class="form-control"
          :class="{ 'is-invalid': $v.user.date_born.$error }"
          placeholder="Fecha Nacimiento"
          v-model.trim="$v.user.date_born.$model"
        />
      </div>
      <div class="col-md-8">
        <label class="form-label">Nacionalidad</label>
        <multiselect
          :class="{ 'is-invalid': $v.user.country_id.$error }"
          v-model="$v.user.country_id.$model"
          :options="countries.map((type) => type.id)"
          :custom-label="countriesSelect"
          placeholder="Busque aqui"
          :allow-empty="false"
        ></multiselect>
      </div>
      <div class="mt-4 modal-footer border-top border-5 border-light">
        <div v-show="!loading">
          <button type="submit" class="btn btn-custom2 float-end">
            Siguiente
            <span class="fas fa-arrow-right" aria-hidden="true"></span>
          </button>
        </div>
        <div v-show="loading">
          <button class="btn btn-custom2 disabled">
            <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
            Cargando
          </button>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'FormPersonal',
  props: {
    submit: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      loading: false,
      user: {},
    }
  },
  computed: {
    ...mapState({
      type_documents: (state) => state.helper.typeDocumentData,
      genders: (state) => state.helper.genderData,
      marital_status: (state) => state.helper.maritalData,
      countries: (state) => state.helper.countryData,
    }),
  },
  validations() {
    let validate = {
      first_name: { required },
      second_name: {},
      first_last_name: { required },
      second_last_name: { required },
      type_document_id: { required },
      nro_document: { required },
      date_born: { required },
      gender: { required },
      marital_status_id: { required },
      country_id: { required },
    }
    return {
      user: validate,
    }
  },
  mounted() {
    this.user = { ...this.data }
  },
  methods: {
    async updateForm() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        await this.submit(
          {
            id: this.user.id,
            first_name: this.user.first_name,
            second_name: this.user.second_name,
            first_last_name: this.user.first_last_name,
            second_last_name: this.user.second_last_name,
            type_document_id: this.user.type_document_id,
            nro_document: this.user.nro_document,
            date_born: this.user.date_born,
            gender: this.user.gender,
            marital_status_id: this.user.marital_status_id,
            country_id: this.user.country_id,
          },
          false
        )
        this.$parent.nextStep()
      }
      this.loading = false
    },
    maritalStatusSelect(opt) {
      if (this.marital_status.length) {
        return this.marital_status.find((x) => x.id === opt).name
      }
      return null
    },
    countriesSelect(opt) {
      if (this.countries.length) {
        return this.countries.find((x) => x.id === opt).name
      }
      return null
    },
  },
}
</script>
